<template>
  <div class="aioffice_swiper">
    <div class="swiper">
      <swiper
        :options="swiperOption"
        class="swiper-container"
        ref="bannerSwiper"
        @someSwiperEvent="callback"
      >
        <swiper-slide v-for="(item, index) in swiperList" :key="index">
          <div class="aioffice_swiper_item">
            <div class="left_content">
              <div class="left_content_text">
                <div class="title">
                  <span class="">{{ item.title }}</span
                  >助手
                </div>
                <div class="tips_list">
                  <div
                    class="tips"
                    v-for="(tip, indext) in item.tips"
                    :key="indext"
                  >
                    <i class="i_checked"></i>{{ tip }}
                  </div>
                </div>
              </div>
              <div class="download_btn">
                <div class="version_btn">
                  <i class="i_windows"></i>
                  <div class="flex flex-col">
                    <div>下载 Windows 版</div>
                    <div>6.2.1 （公测版）</div>
                  </div>
                </div>
                <div class="arrrow_btn">
                  <i class="i_b_arrrow"></i>
                </div>
              </div>
            </div>
            <div class="right_img">
              <img :src="item.images" alt="" />
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import store from "@/store/index.js";
import ai_key from "../../assets/images/aioffice_download/ai_key.png";
import trans_key from "../../assets/images/aioffice_download/trans_key.png";
import voice_key from "../../assets/images/aioffice_download/voice_key.png";
import m_key from "../../assets/images/aioffice_download/m_key.png";

export default {
  name: "carrousel",
  data() {
    return {
      swiperOption: {
        loop: true,
        speed: 1000,
        autoplay: {
          delay: 8000,
          disableOnInteraction: false,
        },
        effect: "slide",
        sliderPerView: 1,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          hideOnClick: true,
        },
      },
      currentHost: "",
      swiperList: [
        {
          title: "AI",
          tips: [
            "支持讯飞星火、文心一言、OpenAI等主流大模型",
            "AI对话、PPT生成、智能写作、智能绘画免费使用",
          ],
          images: ai_key,
        },
        {
          title: "翻译",
          tips: [
            "支持全球60+种语言的翻译转写",
            "支持划词翻译、截屏翻译、翻译打字等多场景",
          ],
          images: trans_key,
        },
        {
          title: "听写",
          tips: [
            "支持高效语音打字，每分钟/500字",
            "支持自动识别，多语言转写，准确率98%",
          ],
          images: voice_key,
        },
        {
          title: "Magic",
          tips: [
            "支持系统功能、一键打开网页、一键打开文件等20+功能自定义",
            "支持单击、长按双按键模式交互",
          ],
          images: m_key,
        },
      ],
    };
  },
  computed: {
    swiper() {
      return this.$refs.bannerSwiper.$swiper; //这里需要加上$  $swiper
    },
  },
  created() {
    const name = store.getters["getCurrentHost"];
    this.currentHost = name;
  },
  mounted() {
    console.log("current", this.swiper);
    new Swiper(".swiper-container", {
      loop: true,
      speed: 1000,
      autoplay: {
        delay: 8000,
        disableOnInteraction: true,
      },
      // 如果需要分页器
      pagination: ".swiper-pagination",
      // 如果需要前进后退按钮
      nextButton: ".swiper-button-next",
      prevButton: ".swiper-button-prev",
      // 如果需要滚动条
      scrollbar: ".swiper-scrollbar",
    });
    //this.$swiper.slideTo(1,3000,true)
  },
  methods: {
    callback() {},
  },
};
</script>

<style lang="scss">
.aioffice_swiper {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../assets/images/bg.png") no-repeat;
  background-size: 100% 100%;
  // background: linear-gradient(143.02deg, #f2fbfd 0%, #d0eaf4 100%) !important;

  .swiper {
    height: 600px !important;
  }
  .i_checked {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url("../../assets/images/checked.png") no-repeat;
    background-size: 100% 100%;
    vertical-align: middle;
  }
  .aioffice_swiper_item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;
    .left_content {
      display: flex;
      flex-direction: column;
      gap: 50px;
      .left_content_text {
        display: flex;
        flex-direction: column;
        gap: 30px;
        .title {
          font-size: 38px;
          font-weight: bold;
          span {
            color: var(--swiper-theme-color);
          }
        }
        .tips_list {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .tips {
            display: flex;
            align-items: center;
            gap: 8px;
          }
        }
      }

      .download_btn {
        display: flex;
        align-items: center;
        height: 60px;
        color: #fff;
        cursor: pointer;
        .version_btn {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          padding: 0 20px;
          height: 100%;
          font-size: 16px;
          background-color: #ff6d1f;
          .flex {
            div:last-child {
              font-size: 14px;
            }
          }
        }
        .arrrow_btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 100%;
          background-color: #ff982a;
        }
        .i_windows {
          display: inline-block;
          width: 30px;
          height: 30px;
          background: url("../../assets/images/windows.png") no-repeat;
          background-size: 100% 100%;
          vertical-align: middle;
        }
        .i_b_arrrow {
          display: inline-block;
          width: 30px;
          height: 30px;
          background: url("../../assets/images/b_arrow.png") no-repeat;
          background-size: 100% 100%;
          vertical-align: middle;
        }
      }
    }
    .right_img {
      width: 30rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
