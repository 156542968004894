// 自定义主题 #f8811f
export const defauleTheme = "#FF8D1A";

export function setMainColor(color = defauleTheme) {
  setRootCssValue("--main-color", color);
}

export function setRootCssValue(key, value) {
  document.documentElement.style.setProperty(key, value);
}
