<template>
	<div id="app">
		<!-- <img alt="Vue logo" src="./assets/logo.png">
		<HelloWorld msg="Welcome to Your Vue.js App"/> -->
		<Index></Index>
	</div>
</template>

<script>
	//import HelloWorld from './components/HelloWorld.vue'
	import Index from './components/Index.vue'

	export default {
		name: 'App',
		components: {
			//HelloWorld
			Index
		},
		meta:{
			title: 'AI-Office'
		}
	}
	
</script>

<style lang="scss">
@import '~@/assets/css/common.scss';
@import '~@/common/font/font.css';
#app{
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
/* 整个滚动条 */
::-webkit-scrollbar {
  width: 6px;
  /* 设置滚动条的宽度 */
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* 设置轨道的背景颜色 */
}

/* 滚动条的滑块 */
::-webkit-scrollbar-thumb {
  background: #888;
  /* 设置滑块的背景颜色 */
}

/* 滑块hover效果 */
::-webkit-scrollbar-thumb:hover {
  background: #888;
  cursor: pointer;
  /* 鼠标悬停时滑块的背景颜色 */
}

</style>
