const zh = {
  lang: "中文", //当前语言
  languages: [
    //语言文件
    {
      value: "zh", //value为语言包文件名（export default zh）
      name: "中文", //语言名称
    },
    {
      value: "en",
      name: "English",
    },
  ],

  //以下为内容区域翻译      //中文
  bannerOne: {
    title: "智能语音鼠标•V8",
    introduce: "让工作更轻松高效",
    explain1: "智慧办公——以人工智能技术为基石",
  },

  //下载
  window: {
    title: "Windows",
    linkOne: "直接下载",
    linkTwo: "内测版",
    version: "版本",
    size: "大小",
    updateTime: "更新时间",
    compatible: "兼容",
    linkExtend: "Windows下载",
    greenLink: "绿色下载",
  },
  mac: {
    title: "MacOS",
    linkOne: "直接下载",
    linkTwo: "App Store",
    version: "版本",
    size: "大小",
    updateTime: "更新时间",
    compatible: "兼容",
    linkExtend: "Mac 下载",
    linkExtend1: "英特尔芯片",
    greenLink: "绿色下载",
  },
  android: {
    title: "智慧办公Android大屏版",
    linkOne: "直接下载",
    linkTwo: "Google Play",
    version: "版本",
    size: "大小",
    updateTime: "更新时间",
    compatible: "兼容",
    greenLink: "绿色下载",
  },
  patch: {
    title: "驱动补丁",
    linkOne: "64位",
    linkTwo: "32位",
    version: "版本",
    size: "大小",
    updateTime: "更新时间",
    compatible: "兼容",
  },
  history: {
    title: "更多版本",
    tip: "请先选择客户端",
    package: "安装包",
    product: "适用产品",
    historyVersion: "版本",
    applicableSystem: "适用系统",
    time: "更新时间",
    download: "下载",
  },
  contact: {
    link: "友情链接",
    oldAddress: "原下载网页地址",
    douyin: "抖音号",
    worktime: "工作时间",
    timeFrame: "周一至周五 9:00 ~ 18:00",
    weChat: "微信同号",
    weChatAccount: "微信公众号",
    customerService: "客服微信",
    video: "视频号",
    business: "天猫",
    x86link: "X86平台",
    armlink: "ARM平台",
  },
  // 定制
  shadow: {
    stepOne: "第一步 下载ShadowAI客户端",
    stepTwo: "第二步 下载卓尧鼠标客户端",
    titleWindow: "ShadowAI 软件",
    titleMac: "ShadowAI 软件",
    titleShadow: "卓尧鼠标客户端",
  },
  // 云电脑
  windowYun: {
    title: "Windows 云电脑",
    linkOne: "立即下载",
    linkTwo: "",
    version: "版本",
    size: "大小",
    updateTime: "更新时间",
    compatible: "兼容",
    linkExtend: "Windows下载",
  },
  //统信
  txYun: {
    title: "统信UOS",
    linkOne: "立即下载",
    linkTwo: "",
    version: "版本",
    size: "大小",
    updateTime: "更新时间",
    compatible: "兼容",
    linkExtend: "Windows Download",
    armTitle: "统信ARM",
  },
  qlYun: {
    title: "麒麟系统",
    linkOne: "立即下载",
    linkTwo: "",
    version: "版本",
    size: "大小",
    updateTime: "更新时间",
    compatible: "兼容",
    linkExtend: "Windows Download",
    armTitle: "麒麟ARM",
  },
};

export default zh;
