import Vue from "vue";
import Vuex from "vuex";

/*
 * 数据格式如下:
 * 用户信息
 * let userInfo = {
 * 	'username' : res.username,
 * 	'phone' : res.mobile,
 * 	'avatar' : res.headImgUrl,
 * 	'role': res.nickname,
 * 	'userId': res.id,
 * 	'permissions': permissions
 *{
 * 	'name': item.name,
 * 	'roleName': ROLE_NAMES[index],
 * 	'roleType': ROLES[index],
 * 	'id': item.id
 * }
 * };
 */

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    //当前语言
    currentLanguage: "",

    //当前域名
    currentHost: "",

    currentDomainName: "",

    webHost: "",
  },

  getters: {
    // this.$store.getters['getCurrentLanguage']
    getCurrentLanguage: (state) => state.currentLanguage,

    getCurrentHost: (state) => state.currentHost,

    getCurrentDomainName: (state) => state.currentDomainName,

    getWebHost: (state) => state.webHost,
  },

  mutations: {
    // this.$store.commit('setCurrentLanguage', {})
    setCurrentLanguage: (state, language) => {
      state.currentLanguage = language;
      //console.log("setCurrentLanguage:" + JSON.stringify(state.currentLanguage))
    },

    setCurrentHost: (state, host) => {
      state.currentHost = host;
    },

    setCurrentFullDomainName: (state, host) => {
      state.currentDomainName = host;
    },

    // 存储aioffice前面的域名
    setWebCurrentHost: (state, host) => {
      state.webHost = host;
    },
  },

  modules: {},
});

export default store;
