<template>
  <div class="swiper">
    <swiper
      :options="swiperOption"
      ref="bannerSwiper"
      @someSwiperEvent="callback"
      class="swiper-container"
    >
      <swiper-slide
        v-if="
          ![
            'yundesk',
            'wo',
            'tele',
            'jiyuai',
            'm70',
            'p70',
            'lefan',
            'pansheng',
            'newland',
            'i70',
            'm7',
            'miaowang',
            'mifi',
            'yff',
            'zuoh',
            'hbs',
            'www.hbs',
            'zxhy',
            'beite'
          ].includes(currentHost)
        "
      >
        <a href="javascript:;">
          <div class="banner-bg">
            <div class="banner-content">
              <div class="banner-text">
                <h3 class="banner-title">{{ $t("bannerCommon.title") }}</h3>
                <p class="banner-intro wrap-text color-o 2">
                  {{ $t("bannerCommon.introduce") }}
                </p>
                <p
                  class="brief-explain wrap-text m-top"
                  style="font-size: 12px"
                >
                  {{ $t("bannerCommon.explain1") }}
                </p>
                <div>
                  <!-- <button class="more-btn font-w600">Learn more</button> -->
                </div>
              </div>
              <div class="banner-product">
                <img src="../../assets/images/ai-img-office.png" />
              </div>
            </div>
          </div>
        </a>
      </swiper-slide>
      <swiper-slide v-if="['www', 'app'].some(host => host === webHost)">
        <a href="javascript:;">
          <div class="banner-bg">
            <div class="banner-content">
              <div class="banner-text">
                <h3 class="banner-title">{{ $t("bannerOne.title") }}</h3>
                <p class="banner-intro wrap-text color-o">
                  {{ $t("bannerOne.introduce") }}
                </p>
                <p class="brief-explain wrap-text m-top">
                  {{ $t("bannerOne.explain1") }}
                </p>
                <div>
                  <!-- <button class="more-btn font-w600">Learn more</button> -->
                </div>
              </div>
              <div class="banner-product">
                <img src="../../assets/images/ai-img-mouse.png" />
              </div>
            </div>
          </div>
        </a>
      </swiper-slide>
      <swiper-slide v-if="['www', 'app'].some(host => host === webHost)">
        <a href="javascript:;">
          <div class="banner-bg">
            <div class="banner-content">
              <div class="banner-text">
                <h3 class="banner-title">{{ $t("bannerTwo.title") }}</h3>
                <p class="banner-intro wrap-text color-o">
                  {{ $t("bannerTwo.introduce") }}
                </p>
                <p class="brief-explain wrap-text m-top">
                  {{ $t("bannerTwo.explain1") }}
                </p>
                <div>
                  <!-- <button class="more-btn font-w600">Learn more</button> -->
                </div>
              </div>
              <div class="banner-product">
                <img src="../../assets/images/ai-img-fyb.png" />
              </div>
            </div>
          </div>
        </a>
      </swiper-slide>
      <swiper-slide v-if="['www', 'app'].some(host => host === webHost)">
        <a href="javascript:;">
          <div class="banner-bg">
            <div class="banner-content">
              <div class="banner-text">
                <h3 class="banner-title">{{ $t("bannerThree.title") }}</h3>
                <p class="banner-intro wrap-text color-o">
                  {{ $t("bannerThree.introduce") }}
                </p>
                <p class="brief-explain wrap-text m-top">
                  {{ $t("bannerThree.explain1") }}
                </p>
                <div>
                  <!-- <button class="more-btn font-w600">Learn more</button> -->
                </div>
              </div>
              <div class="banner-product">
                <img src="../../assets/images/ai-img-keyboard.png" />
              </div>
            </div>
          </div>
        </a>
      </swiper-slide>
      <swiper-slide v-if="['www', 'app'].some(host => host === webHost)">
        <a href="javascript:;">
          <div class="banner-bg">
            <div class="banner-content">
              <div class="banner-text">
                <h3 class="banner-title">{{ $t("bannerFour.title") }}</h3>
                <p class="banner-intro wrap-text color-o">
                  {{ $t("bannerFour.introduce") }}
                </p>
                <p class="brief-explain wrap-text m-top">
                  {{ $t("bannerFour.explain1") }}
                </p>
                <div>
                  <!-- <button class="more-btn font-w600">Learn more</button> -->
                </div>
              </div>
              <div class="banner-product">
                <img src="../../assets/images/ai-img-ej.png" />
              </div>
            </div>
          </div>
        </a>
      </swiper-slide>
      <swiper-slide v-if="['www', 'app'].some(host => host === webHost)">
        <a href="javascript:;">
          <div class="banner-bg">
            <div class="banner-content">
              <div class="banner-text">
                <h3 class="banner-title">{{ $t("bannerFive.title") }}</h3>
                <p class="banner-intro wrap-text color-o">
                  {{ $t("bannerFive.introduce") }}
                </p>
                <p class="brief-explain wrap-text m-top">
                  {{ $t("bannerFive.explain1") }}
                </p>
                <div>
                  <!-- <button class="more-btn font-w600">Learn more</button> -->
                </div>
              </div>
              <div class="banner-product">
                <img src="../../assets/images/ai-img-record.png" />
              </div>
            </div>
          </div>
        </a>
      </swiper-slide>
      <swiper-slide v-if="['www', 'app'].some(host => host === webHost)">
        <a href="javascript:;">
          <div class="banner-bg">
            <div class="banner-content">
              <div class="banner-text">
                <h3 class="banner-title">{{ $t("bannerSix.title") }}</h3>
                <p class="banner-intro wrap-text color-o">
                  {{ $t("bannerSix.introduce") }}
                </p>
                <p class="brief-explain wrap-text m-top">
                  {{ $t("bannerSix.explain1") }}
                </p>
                <div>
                  <!-- <button class="more-btn font-w600">Learn more</button> -->
                </div>
              </div>
              <div class="banner-product">
                <img src="../../assets/images/ai-img-qxm.png" />
              </div>
            </div>
          </div>
        </a>
      </swiper-slide>
      <!-- 云电脑 -->
      <swiper-slide v-if="currentHost == 'yundesk'">
        <a href="javascript:;">
          <div class="banner-bg-yundesk"></div>
        </a>
      </swiper-slide>
      <!-- wo -->
      <swiper-slide v-if="currentHost == 'wo'">
        <a href="javascript:;">
          <div class="banner-bg-wo"></div>
        </a>
      </swiper-slide>
      <!-- tele -->
      <swiper-slide v-if="currentHost == 'tele'">
        <a href="javascript:;">
          <div class="banner-bg-tele"></div>
        </a>
      </swiper-slide>
      <!-- jiyuai -->
      <swiper-slide v-if="currentHost == 'jiyuai'">
        <a href="javascript:;">
          <div class="banner-bg-jiyuai1"></div>
        </a>
      </swiper-slide>
      <swiper-slide v-if="currentHost == 'jiyuai'">
        <a href="javascript:;">
          <div class="banner-bg-jiyuai2"></div>
        </a>
      </swiper-slide>
      <swiper-slide v-if="currentHost == 'jiyuai'">
        <a href="javascript:;">
          <div class="banner-bg-jiyuai3"></div>
        </a>
      </swiper-slide>
      <!-- m70 -->
      <swiper-slide v-if="currentHost == 'm70'">
        <a href="javascript:;">
          <div class="banner-bg-m70"></div>
        </a>
      </swiper-slide>
      <!-- p70 -->
      <swiper-slide v-if="currentHost == 'p70'">
        <a href="javascript:;">
          <div class="banner-bg-p70"></div>
        </a>
      </swiper-slide>
      <!-- lefan -->
      <swiper-slide v-if="currentHost == 'lefan'">
        <a href="javascript:;">
          <div class="banner-bg-lefan"></div>
        </a>
      </swiper-slide>
      <!-- 170 -->
      <swiper-slide v-if="currentHost == 'i70'">
        <a href="javascript:;">
          <div class="banner-bg-i70"></div>
        </a>
      </swiper-slide>
      <!-- pansheng -->
      <swiper-slide v-if="currentHost == 'pansheng'">
        <a href="javascript:;">
          <div class="banner-bg-pansheng"></div>
        </a>
      </swiper-slide>
      <!-- m7-->
      <swiper-slide v-if="currentHost == 'm7'">
        <a href="javascript:;">
          <div class="banner-bg-m7"></div>
        </a>
      </swiper-slide>
      <!-- miaowang-->
      <swiper-slide v-if="currentHost == 'miaowang' || currentHost == 'mifi'">
        <a href="javascript:;">
          <div class="banner-bg-miaowang"></div>
        </a>
      </swiper-slide>
      <swiper-slide v-if="currentHost == 'yff'">
        <a href="javascript:;">
          <div class="banner-bg-yff"></div>
        </a>
      </swiper-slide>
      <swiper-slide v-if="currentHost == 'zuoh'">
        <a href="javascript:;">
          <div class="banner-bg-zuoh"></div>
        </a>
      </swiper-slide>
      <swiper-slide v-if="webHost.includes('hbs')">
        <a href="javascript:;">
          <div class="banner-bg-hbs"></div>
        </a>
      </swiper-slide>
      <swiper-slide v-if="webHost.includes('zxhy')">
        <a href="javascript:;">
          <div class="banner-bg-zxhy"></div>
        </a>
      </swiper-slide>
      <swiper-slide v-if="webHost.includes('beite')">
        <a href="javascript:;">
          <div class="banner-bg-beite"></div>
        </a>
      </swiper-slide>
    </swiper>
    <div class="swiper-pagination"></div>
    <!-- 分页 -->
  </div>
</template>

<script>
import Swiper from "swiper";
import store from "@/store/index.js";
export default {
  name: "carrousel",
  data() {
    return {
      swiperOption: {
        loop: true,
        speed: 1000,
        autoplay: {
          delay: 8000,
          disableOnInteraction: false,
        },
        effect: "slide",
        sliderPerView: 6,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
          dynamicBullets: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          hideOnClick: true,
        },
      },
      currentHost: "",
      webHost: "",
    };
  },
  computed: {
    swiper() {
      return this.$refs.bannerSwiper.$swiper; //这里需要加上$  $swiper
    },
  },
  created() {
    const name = store.getters["getCurrentHost"];
    this.currentHost = name;
    this.webHost = store.getters["getWebHost"];
  },
  mounted() {
    console.log("current", this.swiper);
    new Swiper(".swiper-container", {
      loop: true,
      speed: 1000,
      autoplay: {
        delay: 8000,
        disableOnInteraction: true,
      },
      // 如果需要分页器
      pagination: ".swiper-pagination",
      // 如果需要前进后退按钮
      nextButton: ".swiper-button-next",
      prevButton: ".swiper-button-prev",
      // 如果需要滚动条
      scrollbar: ".swiper-scrollbar",
    });
    //this.swiper.slideTo(1,3000,false)
  },
  methods: {
    callback() {},
  },
};
</script>

<!-- banner原内容备份 -->
<!-- <div class="banner-content">
	<div class="banner-text">
		<h3 class="banner-title">Intelligent voice mouse • V8</h3>
		<p class="banner-intro wrap-text color-grey">You can type 400 words a minute</p>
		<p class="brief-explain wrap-text">vocie typing | 100+global languages | 24 dialects</p>
		<div>
			<button class="more-btn font-w600">Learn more</button>
		</div>
	</div>
	<div class="banner-product">
		<img src="../../assets/images/ai-img-mouse.png" />
	</div>
</div> -->
